import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { ProjectData } from './Data';
// import { useDispatch, useSelector } from 'react-redux';
// import { getNewUsers } from '../../../actions/auth';
// import { deleteData } from '../../../actions/posts';
import GoToTop from '../../js/GoToTop';

const Raktmani = () => {
    // const dispatch = useDispatch();
    // const [updateId, setId] = useState(null);
    const [userDetails, setUserDetails] = useState()
    // const [memberModal, setMemberModal] = useState(false)
    // const [deleteModal, setDeleteModal] = useState(false)
    // const { newUsers, isLoading } = useSelector((state) => state.posts);
    const [search, setsearch] = useState(null)
    const [ no, setNo ] = useState(20);

    useEffect(() => {
        document.title = "Raktmani - Mukesh Goyal"
      }, [])    
    
    // useEffect(() => {
    //     dispatch(getNewUsers());
    // }, [dispatch])

    const searchSpace = (event) => {
        let keyword = event.target.value
        setsearch(keyword)
    }

    // const forceUpdate = window.location.reload();

    // const memberDetail = (id) => {
    //     setId(id);
    //     setMemberModal(true)
    // }

    // const deleteUser = (id) => {
    //     setId(id)
    //     setDeleteModal(true)
    // }

    const loadMore = () => {
        setNo((no) => no+20)
    }
    
    const formatDate = (date) => {
        if(!date) {
            return (`NA`)
        } else {
        const date1 = new Date(date)
        const formattedDate = date1.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric"
        })

        return(formattedDate)
        }
    }

    const member = ProjectData.filter((data1 => {
        if(search === null) {
            return data1
        } else if ( data1.donorname.toLowerCase().includes(search.toLowerCase()) || data1.fathername.toLowerCase().includes(search.toLowerCase()) || data1.locality.toLowerCase().includes(search.toLowerCase()) || data1.bloodbank.toLowerCase().includes(search.toLowerCase()) ) {
            return data1
        }
    })).map((user, i) => {
        return(
            <>
            <div className="col-12 text-capitalize px-0" key={i}><hr className='m-0' />
                <div className="row align-items-center py-2" style={{margin: 0}}>
                    <div className="col-1 pr-lg-1 pr-0">
                        <p className='m-0'>{i+1}</p>
                    </div>
                    <div className="col-2 px-lg-1 px-0">
                        <p className='m-0'>{formatDate(user.date)}<br /><span className='txtbox'>{user.day}</span></p>
                    </div>
                    <div className="col-4 px-lg-1 px-0">
                        <p className='m-0'>{user.donorname}<br /><span className='txtbox'>{user.gender}</span></p>
                    </div>
                    <div className="col-3 px-lg-1 px-0">
                        <p className='m-0'>{user.fathername}</p>
                    </div>
                    <div className="col-2 pl-lg-1 pl-0">
                        <p className='m-0'>{user.bloodbank}</p>
                    </div>

                    {/* <div className="col-1 pr-lg-1 pr-0">
                        <p className='m-0'></p>
                    </div>
                    <div className="col-3 px-lg-1 px-0">
                        <p className='py-0 m-0'>{formatDate(user.date)}</p>
                    </div>
                    <div className="col-3 px-lg-1 px-0">
                        <p className='m-0 txtbox'>{user.gender}</p>
                    </div> */}

                </div>
            </div>
                {/* <tr>
                    <th>{i+1}</th>
                    <th> {formatDate(user.date)} </th>
                    <th> {user.donorname} </th>
                    <th> {user.fatherName} </th>
                    <th> {user.gender} </th>
                    <th> {user.bloodGroup} </th>
                    <th> {user.locality} </th>
                    <th> {user.bloodBank} </th>
                </tr> */}
            
            </>
        )
    })

    return (
        <div>
            <section id="raktmani" className="raktmani">
                <div className='container text-center'>
                    <div className="row align-items-start my-lg-2 mt-0 mb-4" style={{margin: 0}}>
                        <div className="col-lg-6 pr-lg-1">
                            <h2 className='glow-heading-light pt-0 text-lg-left text-center'><strong>Raktmani</strong> Donor List</h2>
                        </div>
                        <div className="col-lg-6 pl-lg-1 text-lg-right text-center">
                            <div onChange={(e) => searchSpace(e)} className="px-lg-0 px-2">
                                <input type="text" placeholder='Search Donors' className='customized-input' />
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center py-2 bg-secondary text-white" style={{margin: 0}}>
                        <div className="col-1 pr-lg-1 pr-0">
                            <p className='m-0'>Sr No.</p>
                        </div>
                        <div className="col-2 px-lg-1 px-0">
                            <p className='m-0'>Date<br /><span className='txtbox'>Day</span></p>
                        </div>
                        <div className="col-4 px-lg-1 px-0">
                            <p className='m-0'>Donor Name<br /><span className='txtbox'>Gender</span></p>
                        </div>
                        <div className="col-3 px-lg-1 px-0">
                            <p className='m-0'>Father's Name</p>
                        </div>
                        <div className="col-2 pl-lg-1 pl-0">
                            <p className='m-0'>Blood Bank</p>
                        </div>
                    </div>
                    <div className="row align-items-start" style={{margin: 0}}>
                        {member.slice(0,no)}
                    </div>
                    <a className={ (ProjectData.length <= no) ? "btn hidden" : "btn" } onClick={loadMore}>Load More</a>


                    {/* <div className="row align-items-start p-2 text-white" style={{margin: 0}}>

                        <table className="table">
                                <thead className="thead-dark">
                                    <tr>
                                        <th>Sr No.</th>
                                        <th>Date</th>
                                        <th>Donor Name</th>
                                        <th>Father's Name</th>
                                        <th>Gender</th>
                                        <th>Blood Group</th>
                                        <th>Locality</th>
                                        <th>Blood Bank</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {member.slice(0,no)}
                                </tbody>
                            
                        </table>
                        <a className={ (ProjectData.length <= no) ? "btn hidden" : "btn" } onClick={loadMore}>Load More</a>
                    </div> */}

                    {/* {isLoading ? 
                    <div colSpan={5} className='text-center py-4'>
                        <div className="loader"></div>
                    </div>
                    :
                    <div className="row align-items-start" style={{margin: 0}}>
                        {member.slice(0,no)}
                    </div>
                    } */}

                    {/* <a className={ (newUsers.length <= no) ? "load-btn hidden" : "load-btn" } onClick={loadMore}>View More</a> */}

                    {/* <div className="member-list">
                        <table className="table">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Serial No.</th>
                                    <th>Name</th>
                                    <th>Phone No.</th>
                                    <th>Email</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {isLoading ? 
                            <tbody>
                                <tr><td colSpan={5} className='text-center py-4'><div className="loader"></div></td></tr>
                            </tbody>
                            :
                            <tbody>
                                {member.slice(0,no)}
                            </tbody>
                            }
                            
                        </table>
                        <a className={ (newUsers.length <= no) ? "load-btn hidden" : "load-btn" } onClick={loadMore}>Load More</a>
                    </div> */}

                    {/* <Modal
                        isOpen={memberModal} 
                        onRequestClose={() => {setMemberModal(false); setId(null)}}
                        style={{content: {position: 'fixed', top: '20vh', left: '6vw', right: '6vw', bottom: '6vh'}}}
                    >
                        <div className="container p-0">
                            <div className="row align-items-start" style={{margin: 0}}>
                                <div className="col-11 my-2">
                                    <h2 className="glow-heading-light">Member Details</h2><hr />
                                </div>
                                <div className="col-1 text-lg-center text-right">
                                    <a type='button' onClick={() => {setMemberModal(false); setId(null)}}  style={{position: 'fixed', fontSize: '1.8rem', fontWeight: 'bold'}} >x</a>
                                </div>

                            {newUsers.filter((user1) => {
                            if (user1._id === updateId) {
                                return user1
                            }
                            }).map((user) => {
                                return(
                                <div className="col-12 my-2 text-left" key={user._id}>
                                    <div className="row align-items-start" style={{margin: 0}}>
                                        <div className="col-lg-6 pr-lg-1 pr-0">
                                            <p className="p-1 m-0">Name</p>
                                            <h5 className='p-1 bg-light'>{user.firstName} {user.lastName}</h5>
                                        </div>
                                        <div className="col-lg-6 pl-lg-1 pl-0">
                                            <p className="p-1 m-0">Email</p>
                                            <h5 className='p-1 bg-light'>{user.email}</h5>
                                        </div>
                                        <div className="col-lg-4 pr-lg-1 pr-0">
                                            <p className="p-1 m-0">Phone</p>
                                            <h5 className='p-1 bg-light'>{user.phone}</h5>
                                        </div>
                                        <div className="col-lg-3 px-lg-1 px-0">
                                            <p className="p-1 m-0">DoB</p>
                                            <h5 className='p-1 bg-light'>{formatDate(user.dob)}</h5>
                                        </div>
                                        <div className="col-lg-5 pl-lg-1 pl-0">
                                            <p className="p-1 m-0">Address</p>
                                            <h5 className='p-1 bg-light'>{user.state}, {user.country} - {user.pin}</h5>
                                        </div>
                                        <div className="col-lg-6 pr-lg-1 pr-0">
                                            { user.descSt === 'Non-Student' ? <>
                                            <p className="p-1 m-0">In Academics?</p>
                                            <h5 className='p-1 bg-light'>{user.descSt}</h5>
                                            </> : <>
                                            <p className="p-1 m-0">Student Type</p>
                                            <h5 className='p-1 bg-light'>{user.typeSt}</h5>
                                            <p className="p-1 m-0">Institute</p>
                                            <h5 className='p-1 bg-light'>{user.instituteName}, {user.instituteState} - {user.institutePin}</h5>
                                            </> }
                                        </div>
                                        <div className="col-lg-12 text-center mt-3">
                                            <p className="p-1 m-0">{user.firstName} joined THC on <span className='font-weight-bold'>{formatDate(user.date)}</span>, and has
                                            { user.cc === 'true' ?<span className='px-1 font-weight-bold'>Opted</span>:<span className='px-1 font-weight-bold'>Not Opted</span>}
                                            for Campus Connect.</p>
                                        </div>
                                        <div className="col-lg-12 text-center">
                                            <p className="p-1 m-0">Remove member?
                                            <button type='button' className='btn-primary ml-1' onClick={() => {setMemberModal(false); deleteUser(user._id)}}>Delete</button>
                                            </p>
                                        </div>

                                    </div>

                                </div>
                                )
                            })}
                                    
                            </div>
                        </div>
                    </Modal> */}

                    {/* <Modal 
                        isOpen={deleteModal} 
                        onRequestClose={() => setDeleteModal(false)}
                        style={{content: {position: 'fixed', top: '22vh', left: '16vw', right: '16vw', bottom: '14vh'}}}
                    >
                        <div className="container text-center py-5">
                            <h4>Are you sure want to delete this user?</h4>
                            <button className='btn mr-1' type='button' onClick={() => {dispatch(deleteData('NewUser', updateId)); document.location.reload()}}>Yes</button>
                            <button className='btn ml-1' type='button' onClick={() => {setId(null); setDeleteModal(false)}}>No</button>
                        </div>
                    </Modal> */}

                    {/* <GoToTop /> */}
                </div>
            </section>
            <GoToTop/>
        </div>
    )
}

export default Raktmani