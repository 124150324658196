import React, { useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import symbol from '../../media/bjp_logo.png'
import '../style.css'
import '../main.css'

const Navbar = () => {
    const [topbar, setTopbar] = useState(false)

    const showTopbar = () => setTopbar(!topbar)

    return (
        <header className='fixed-top'>

            <div className="header-top">
                <div className="container-xl">
                    <div className="row d-flex align-items-center justify-content-between">
                        <div>
                            <a href="mailto:mukeshgoyalbjp@gmail.com"><i className="bi-envelope-fill"></i><span>mukeshgoyalbjp@gmail.com</span></a>
                        </div>
                        <div>
                            <a href="tel:+919595130130" target="_blank"><i className="bi-telephone-outbound-fill"></i><span>(+91) 9595 130 130</span></a>
                        </div>
                        {/* <a href="https://goo.gl/maps/Tnfkoto4zdcsmiHJ7" target="_blank"><i className="bi-geo-alt-fill"></i> Plot No. 70, Sector 5, IMT Manesar, Gurugram - 122050</a> */}
                        <div className="social-links text-lg-right text-center">
                            <a href="mailto:mukeshgoyalbjp@gmail.com"><i className="bi-envelope"></i></a>
                            <a href="https://www.facebook.com/mukeshgoyalbjp" className="nav-color" target="_blank"><i className="bi-facebook"></i></a>
                            <a href="https://www.twitter.com/mukeshgoyalbjp" className="nav-color" target="_blank"><i className="bi-twitter"></i></a>
                            <a href="https://www.instagram.com/mukeshgoyalbjp" className="nav-color" target="_blank"><i className="bi-instagram"></i></a>
                            <a href="https://www.youtube.com/@mukeshgoyalbjp" className="nav-color" target="_blank"><i className="bi-youtube"></i></a>
                        </div>

                    </div>
                </div>
            </div>

            <nav id='navbar' className='navbar navbar-expand-lg b-dark'>
                <div className="container navbar-container">

                    <Link className="navbar-brand" to='/'>
                        <div className='d-flex  align-items-center justify-content-left'>
                            <img src={symbol} alt="kamal - lotus" width="60" height="60"/>
                            <h4 className='mb-0'><strong>Mukesh </strong>Goyal<br/><p className='subtitle mb-0'>Kotputli, Rajasthan</p></h4>
                        </div>
                    </Link>

                    <div className="d-flex nav-ham">

                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <NavLink className={({ isActive }) => isActive ? 'active nav-link' : 'nav-link'} to='/'>Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={({ isActive }) => isActive ? 'active nav-link' : 'nav-link'} to='/about'>Biography</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={({ isActive }) => isActive ? 'active nav-link' : 'nav-link'} to='/raktmani'>Raktmani</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={({ isActive }) => isActive ? 'active nav-link' : 'nav-link'} to='/contact'>Contact</NavLink>
                            </li>
                        </ul>
                        
                        <div className={topbar ? 'div1 cross' : 'div1'} onClick={showTopbar}>
                            <div className='sub1'></div>
                            <div className='sub2'></div>
                            <div className='sub3'></div>
                        </div>
                    </div>

                </div>
                <div className={topbar ? 'sidebar active' : 'sidebar'}>
                    <div className="container h-100">
                        <div>
                            <ul className="sidebar-list">
                                <li className="nav-item">
                                    <NavLink onClick={showTopbar} className={({ isActive }) => isActive ? 'active nav-link' : 'nav-link'} to='/'>Home</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink onClick={showTopbar} className={({ isActive }) => isActive ? 'active nav-link' : 'nav-link'} to='/about'>Biography</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink onClick={showTopbar} className={({ isActive }) => isActive ? 'active nav-link' : 'nav-link'} to='/raktmani'>Raktmani</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink onClick={showTopbar} className={({ isActive }) => isActive ? 'active nav-link' : 'nav-link'} to='/contact'>Contact</NavLink>
                                </li>
                            </ul>
                            <div className="social-links text-lg-right text-center">
                                <a href="https://www.facebook.com/mukeshgoyalbjp" className="nav-color" target="_blank"><i className="bi-facebook"></i></a>
                                <a href="https://www.twitter.com/mukeshgoyalbjp" className="nav-color" target="_blank"><i className="bi-twitter"></i></a>
                                <a href="https://www.instagram.com/mukeshgoyalbjp" className="nav-color" target="_blank"><i className="bi-instagram"></i></a>
                                <a href="https://www.youtube.com/@mukeshgoyalbjp" className="nav-color" target="_blank"><i className="bi-youtube"></i></a>
                                <a href="mailto:mukeshgoyalbjp@gmail.com"><i className="bi-envelope"></i></a>
                            </div>
                        </div>
                    </div>
                </div>


            </nav>

        </header>
    )
}

export default Navbar