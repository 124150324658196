import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import aboutpic from '../../media/mukesh_goyal.jpg'
import '../style.css'
import '../main.css'
import GoToTop from '../../js/GoToTop'

const About = () => {

  useEffect(() => {
    document.title = "Biography - Mukesh Goyal | Kotputli, Rajasthan"
  }, [])

  return (
    <div>
        <section id="about" className="about">
          <div className="bgc p-lg-5 p-2">
            <div className="container text-center">
                <div className="section-title pt-5">
                    <h2>Biography</h2>
                </div>

                <div className="row align-items-center text-left">
                    <div className="col-lg-5 text-center">
                      <img className="mb-lg-0 mb-5" src={aboutpic} alt="Mukesh Goyal"/>
                    </div>
                    <div className="col-lg-7">
                      <h2 className='h2-head'>Shri Mukesh Goyal</h2>
                      <p className="lead py-4">Shri Mukesh Goyal is a 'jansevak' from the Rajasthan Unit of Bharatiya Janta Party. For the last few decades, he has been dedicatedly working for his Kotputli constituency, to make it into a "Samarth Kotputli".</p>
                      {/* <Link className="btn" to='/about'>Know More</Link> */}
                    </div>
                </div>
            </div>
          </div>
        </section>
        <section className='pb-5'>
          <div className="container text-center">
            <div className="section-title">
                <h2>संगठनात्मक वृत्त</h2>
            </div>
            <div>
              <ul className='text-left'>
                <li>वर्ष 1981 से राष्ट्रीय स्वयंसेवक संघ का स्वयंसेवक।</li>
                <li>अखिल भारतीय विद्यार्थी परिषद् में 1987 से 1998 तक जिला, विभाग एवं प्रदेश स्तर पर विभिन्न दायित्वों का निर्वहन किया एवं छात्र राजनीति में सक्रिय भागीदारी रही।</li>
                <li>भाजपा युवा मोर्चा में नगर एवं प्रदेश कार्यकारिणी में विभिन्न दायित्वों का निर्वहन किया (1998-2004)</li>
                <li><strong>प्रदेश मंत्री</strong> - भाजपा रोजगार प्रकोष्ठ, राजस्थान (2005-2008)</li>
                <li><strong>सदस्य</strong> - जन अभाव अभियोग निराकरण एवं सतर्कता समिति, कोटपूतली (2004 2008)</li>
                <li><strong>अध्यक्ष</strong> - भारत विकास परिषद्, कोटपूतली (2008- 2010)</li>
                <li><strong>जिला मंत्री</strong> - भाजपा जयपुर देहात (2009-2015)</li>
                <li><strong>जिला अध्यक्ष</strong> - भारत विकास परिषद्, जयपुर (2011-2012)</li>
                <li><strong>जिला अध्यक्ष</strong> - दीनदयाल उपाध्याय स्मृति मंच, जयपुर देहात (2011-12)</li>
                <li><strong>संयोजक</strong> - रक्तमणि अभियान (दैनिक रक्तदान कार्यक्रम 17 सितम्बर 2015 से अनवरत )</li>
                <li><strong>संयोजक</strong> - उपहार अभियान (जरूरतमंदों की सेवार्थ 25 दिसम्बर 2015 से अनवरत )</li>
                <li><strong>प्रदेश अध्यक्ष</strong> - सरदार पटेल खेल परिषद्, राजस्थान (वर्तमान)</li>
                <li><strong>कोषाध्यक्ष</strong> - व्यापार महासंघ कोटपूतली (वर्तमान)</li>
                <li><strong>प्रधान</strong> - राजस्थान राज्य भारत स्काउट व गाइड स्थानीय संघ कोटपूतली (वर्तमान)</li>
                <li><strong>मण्डल प्रधान</strong> - राजस्थान राज्य भारत स्काउट व गाइड, जयपुर मण्डल (वर्तमान)</li>
              </ul>
            </div>
          </div>

        </section>
        <GoToTop/>
    </div>
  )
}

export default About