import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import heropic from '../../media/mukesh_goyal.jpg'
import about from '../../media/mukesh_goyal.jpg'
import symbol from '../../media/bjp_logo.png'
import call from '../../media/missed-call.png'
import Typewriter from 'typewriter-effect'
import '../style.css'
import '../main.css'
import GoToTop from '../../js/GoToTop'

const Home = () => {

  useEffect(() => {
    document.title = "Mukesh Goyal - Kotputli, BJP Rajasthan | Rajasthan Assembly Elections 2023 | mukeshgoyal.in"
  }, [])

  return (
    <div>

      <section id="home" className="home">
        <div className="container">

          <div className="row align-items-center">

            <div className="text-center col-lg-6">
            <h1 data-aos="fade-right" data-aos-delay="300"><strong>मुकेश गोयल</strong></h1>
                {/* <Typewriter
                  options={{
                    strings: ['राष्ट्रवादी','ईमानदार','कर्मठ'],
                    autoStart: true,
                    loop: true,
                  }}
                /> */}
            </div>

            <div className="col-lg-6 text-lg-right text-center">
            <h1 data-aos="fade-right" data-aos-delay="300"><strong>Mukesh Goyal</strong></h1>
              {/* <img data-aos="fade-down" data-aos-delay="400" className="rounded mx-auto d-block" src={heropic} alt="Mukesh Goyal"/>	 */}
            </div>

          </div>
        
        </div>

      </section>

      <div className="vsdiv">
            <div className="container">
            <div className="vidhansabha mx-auto d-block" data-aos="fade-up" data-aos-delay="500">

                <div className="row align-items-center">
                <div className="col-lg-5">
                    <div>
                    {/* <h4 className='heading4'>चुनाव चिन्ह</h4> */}
                    <img className='symbol' src={symbol} alt="kamal - lotus"/>
                    {/* <h4 className='heading4'><strong>भोजन भरी थाली</strong></h4> */}
                    </div>
                </div>
                <div className="col-lg-7">
                    <div>
                    <h5 className='heading5'>
                        To connect with <strong>Mukesh Goyal</strong>, give a missed call to</h5>
                    <a href="tel:+919595130130" target="_blank">
                    <h3 className='heading3 mb-lg-0'>
                            <img src={call} alt="missed call"/> <strong>9595 130 130</strong>
                    </h3>
                            </a>
                    {/* <h3 className='heading3'><strong>मेंहदावल विधान सभा (312)</strong></h3> */}
                    </div>
                </div>
                </div>
            </div>

            </div>
        </div>
      
      <section id="about" className="home-about">
        <div className="container">
          {/* <div className="section-title mt-4">
          </div> */}

          <div className="row align-items-center">
              <div className="col-lg-5 text-center">
                <img className="" src={about} alt="Anil Tripathi"/>
              </div>
              <div className="col-lg-7 pt-lg-0 pt-5">
                <h5>About</h5>
                <h2 className='h2-head'>Shri Mukesh Goyal</h2>
                <p className="text-left py-4">Shri Mukesh Goyal is a 'jansevak' from the Rajasthan Unit of Bharatiya Janta Party. For the last few decades, he has been dedicatedly working for his Kotputli constituency, to make it into a "Samarth Kotputli".</p>
                <Link className="btn" to='/about'>Know More</Link>
              </div>
          </div>

        </div>
      </section>
      <GoToTop />
    </div>
  )
}

export default Home