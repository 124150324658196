import React from 'react'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import About from './about/About';
import Contact from './contact/Contact';
import Footer from './footer/Footer';
import Home from './home/Home'
import Navbar from './Navbar/Navbar';
import Raktmani from './raktmani/Raktmani';

const Index = () => {
  return (
    <Router>
        <Navbar />
        <Routes>
            <Route exact path='/' element={ <Home/> } />
            <Route path='/about' element={ <About/> } />
            <Route path='/contact' element={ <Contact/> } />
            <Route path='/raktmani' element={<Raktmani />} />
        </Routes>
        <Footer/>
    </Router>
  )
}

export default Index