import React, { useEffect } from 'react'
import pic from '../../media/mukesh_goyal.jpg'
import '../style.css'
import '../main.css'
import GoToTop from '../../js/GoToTop'

const Contact = () => {

    useEffect(() => {
        document.title = "Contact - Mukesh Goyal"
    }, [])

return (
  <div>
        <section id="about" className="about">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12 text-left">
                        <div className="section-title">
                            <h2>Get in touch</h2>
                        </div>
                        <i className="bi-envelope"></i> <a href="mailto:mukeshgoyalbjp@gmail.com">mukeshgoyalbjp@gmail.com</a><br/>
                        <i className="bi-facebook"></i> <a href="https://www.facebook.com/mukeshgoyalbjp" target="_blank">Facebook</a><br/>
                        <i className="bi-twitter"></i> <a href="https://www.twitter.com/mukeshgoyalbjp" target="_blank">Twitter</a><br/>
                        <i className="bi-instagram"></i> <a href="https://www.instagram.com/mukeshgoyalbjp" target="_blank">Instagram</a><br/>
                        <i className="bi-youtube"></i> <a href="https://www.youtube.com/@mukeshgoyalbjp" target="_blank">YouTube</a><br/><br/>
                        {/* <address><i className="bi-geo-alt-fill"></i> ग्राम व पोस्ट - करमां कला,<br/> ब्लॉक व तहसील - मेंहदावल,<br/> जनपद - संतकबीर नगर</address> */}
                    </div>
                    {/* <div className="col-lg-5 text-center">
                        <img className="" src={pic} alt="Anil Tripathi"/>
                    </div> */}
                </div>
            </div>
        </section>
      <GoToTop/>
  </div>
)
}

export default Contact