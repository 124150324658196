import React from 'react'

const Footer = () => {
  return (
    <div>
        <section id="work" className="work">
            <div className="container text-center">
                <div className="row d-flex align-items-start">

                    <div className="col-lg-6 mb-4">
                        <div className="fb-page" data-href="https://www.facebook.com/mukeshgoyalbjp" data-tabs="timeline" data-width="340" data-height="" data-small-header="true" data-adapt-container-width="false" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/mukeshgoyalbjp" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/mukeshgoyalbjp">Mukesh Goyal - Facebook</a></blockquote></div>
                    </div>

                    <div className="col-lg-6 mb-4">
                        <a className="twitter-timeline" href="https://twitter.com/mukeshgoyalbjp" data-width="340" data-height="500">Tweets by mukeshgoyalbjp</a>
                    </div>
                    
                </div>
                <div className="col-lg-12 text-lg-center text-center social-links mt-5">
                    <a href="mailto:mukeshgoyalbjp@gmail.com"><i className="bi-envelope"></i></a>
                    <a href="https://www.facebook.com/mukeshgoyalbjp" className="nav-color" target="_blank"><i className="bi-facebook"></i></a>
                    <a href="https://www.twitter.com/mukeshgoyalbjp" className="nav-color" target="_blank"><i className="bi-twitter"></i></a>
                    <a href="https://www.instagram.com/mukeshgoyalbjp" className="nav-color" target="_blank"><i className="bi-instagram"></i></a>
                    <a href="https://www.youtube.com/@mukeshgoyalbjp" className="nav-color" target="_blank"><i className="bi-youtube"></i></a>
                </div>

            </div>
        </section>

        <footer>
            <div className="container">
                <div className="row d-flex align-items-center">

                    <div className="col-lg-6 text-lg-left text-center">
                    &copy; 2024 All Rights Reserved by <strong>Mukesh Goyal</strong>
                    </div>
                    
                    <div className="col-lg-6 text-lg-right text-center dev-cred">
                    <p><a href="https://brey.in" target="_blank">Built by Brey Digital</a></p>
                    {/* <address><i className="bi-geo-alt-fill"></i> ग्राम व पोस्ट - करमां कला,<br/> ब्लॉक व तहसील - मेंहदावल,<br/> जनपद - संतकबीर नगर</address> */}
                    </div>

                </div>
            </div>
        </footer>
    </div>
  )
}

export default Footer